<template>
  <div>
    <v-dialog
      v-model="inputValue"
      persistent
      :max-width="$vuetify.breakpoint.xs ? '100%' : '60vw'"
      content-class="record-video-badge-selection"
    >
      <v-card>
        <v-card-title class="d-flex flex-column justify-center">
          <div class="d-flex headline font-weight-bold mb-2 mt-2">Record Videos</div>
        </v-card-title>
        <div>
          <div class="d-flex justify-center">
            <a
              href="https://bit.ly/pilotcityvlogs2021"
              target="_blank"
              style="text-decoration: none"
              class="see-video-detail-btn"
            >
              <v-btn rounded depressed dark small color="green" class="font-weight-bold mb-">
                See video details, instructions & examples
              </v-btn>
            </a>
          </div>
        </div>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <v-container>
            <v-row>
              <v-col
                v-for="(item, index) in data.VlogOptions.sort()"
                :key="index"
                class="mt-3 d-flex video-select-badge"
                cols="12"
                md="4"
              >
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-btn rounded small outlined depressed @click="openVideoAskDialog(item)">
                        <v-icon left color="red">mdi-youtube</v-icon>
                        {{ item.name }}
                      </v-btn>
                    </div>
                  </template>
                  <span>{{ item.nameDesc }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <div class="d-flex justify-center mt-5">
          <a href="#" class="ma-2 grey--text font-weight-bold ml-1" @click="saveUploadUrl()">
            Use YouTube links instead
          </a>
        </div>
        <div class="d-flex justify-center">
          <v-btn
            class="font-weight-bold mb-8 mt-6"
            x-large
            depressed
            dark
            rounded
            @click="inputValue = false"
          >
            Close
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="VideoAskDialog"
      persistent
      content-class="record-video-badge-selection"
      :max-width="$vuetify.breakpoint.xs ? '100%' : '60vw'"
    >
      <v-card>
        <v-container class="ma-0 pa-0">
          <iframe
            v-if="VideoAskDialog && currentVlog"
            :src="
              currentVlog.link +
              `#contact_email=${encodeURI(user.email)}&contact_name=${encodeURI(
                user.firstName
              )}&program_id=${encodeURI(programDoc.data._id)}&index=${adkIndex}`
            "
            allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
            width="100%"
            height="500px"
            style="border: none; border-radius: 0px"
            class="ma-0 pa-0"
          >
          </iframe>
        </v-container>
        <v-container class="d-flex justify-center flex-column">
          <p class="mb-3 font-weight-bold mt-3">
            After recording your training video, please let the tool upload the same on our server.
            It would take from few seconds to several moments to upload the video. Once you see the
            thank you message, you can close the dialog box.
          </p>
          <div class="d-flex flex-row justify-center mt-3 mb-5">
            <v-btn
              class="ma-2 white--text close font-weight-bold"
              x-large
              color="red"
              rounded
              depressed
              @click="closeRecordVideoModal()"
            >
              <loader v-if="isFetchingProgram" />
              <v-icon v-else left>mdi-close</v-icon>
              Exit
            </v-btn>
            <v-btn
              class="ma-2 white--text font-weight-bold"
              :disabled="isFetchingProgram"
              x-large
              dark
              color="green"
              rounded
              depressed
              @click="closeRecordModal()"
            >
              <loader v-if="isFetchingProgram" />
              <v-icon v-else left>mdi-check-all</v-icon>
              Submit
            </v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api';
import { useUserState, useUserActions } from '@/store';
import data from '../../../static-data/static.json';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },
    fetchProgram: {
      type: Function,
      default: () => {}
    },
    program: {
      type: Object,
      default: () => {}
    },
    adkIndex: {
      type: Number,
      default: 0
    }
  },

  setup(props, ctx) {
    const currentVlog = ref('');
    const { updateAdkData } = useUserActions(['updateAdkData']);
    const VideoAskDialog = ref(false);
    const isFetchingProgram = ref(false);
    const { user } = useUserState(['user']);
    const inputValue = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('toggle', newVal);
      }
    });

    const programDoc = computed({
      get: () => props.program,
      set: newVal => {
        ctx.emit('get-data', newVal);
      }
    });

    const adkIndex = computed({
      get: () => props.adkIndex,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const index = adkIndex.value;

    const fieldIndex = programDoc.value.data.adks[index].fields.findIndex(field => {
      if (field.name) {
        return field.name === 'metagrabber';
      }
      return false;
    });
    async function populate(val) {
      const train1 = ref({
        name: val.nameDesc,
        link: '',
        videoask_question_url: val.link,
        required: false,
        is_processing: true,
        unlocked: false,
        completed: false
      });
      if (
        !programDoc.value?.data?.adks[index]?.fields[fieldIndex]?.setup?.videoLinks ||
        programDoc.value?.data?.adks[index]?.fields[fieldIndex]?.setup?.videoLinks?.length < 1
      ) {
        programDoc.value.data.adks[index].fields[fieldIndex].setup.videoLinks = [
          { ...train1.value }
        ];
      } else {
        programDoc.value.data.adks[index].fields[fieldIndex]?.setup?.videoLinks?.push(train1.value);
      }
      await updateAdkData({
        program_id: programDoc.value.data._id.toString(),
        document_type: 'Program',
        data: programDoc.value?.data?.adks[index]
      });
      ctx.emit('get-data', programDoc.value);
    }

    function populateYoutubeVideo() {
      const train1 = ref({
        name: '',
        link: '',
        videoask_question_url: '',
        required: false,
        is_processing: false,
        unlocked: false,
        completed: false
      });
      if (
        !programDoc.value?.data?.adks[index]?.fields[fieldIndex]?.setup?.videoLinks ||
        programDoc.value?.data?.adks[index]?.fields[fieldIndex]?.setup?.videoLinks?.length < 1
      ) {
        programDoc.value.data.adks[index].fields[fieldIndex].setup.videoLinks = [
          { ...train1.value }
        ];
      } else {
        programDoc.value.data.adks[index].fields[fieldIndex]?.setup?.videoLinks?.push(train1.value);
      }
      ctx.emit('get-data', programDoc.value);
    }

    async function openVideoAskDialog(item) {
      await populate(item);
      currentVlog.value = item;
      VideoAskDialog.value = true;
    }

    function saveUploadUrl() {
      populateYoutubeVideo();
      inputValue.value = false;
    }

    const closeRecordVideoModal = async () => {
      const removeItem =
        programDoc.value?.data?.adks[index]?.fields[fieldIndex]?.setup?.videoLinks?.slice(-1)[0];
      const indx =
        programDoc.value?.data?.adks[index]?.fields[fieldIndex]?.setup?.videoLinks?.lastIndexOf(
          removeItem
        );
      programDoc.value?.data?.adks[index]?.fields[fieldIndex]?.setup?.videoLinks?.splice(indx, 1);
      await updateAdkData({
        program_id: programDoc.value.data._id.toString(),
        document_type: 'Program',
        data: programDoc.value?.data?.adks[index]
      });
      isFetchingProgram.value = false;
      VideoAskDialog.value = false;
      inputValue.value = false;
      ctx.emit('get-data', programDoc.value);
    };

    const closeRecordModal = async () => {
      isFetchingProgram.value = false;
      VideoAskDialog.value = false;
      inputValue.value = false;
    };

    return {
      inputValue,
      data,
      openVideoAskDialog,
      currentVlog,
      user,
      programDoc,
      VideoAskDialog,
      isFetchingProgram,
      closeRecordVideoModal,
      closeRecordModal,
      populateYoutubeVideo,
      saveUploadUrl
    };
  }
};
</script>
